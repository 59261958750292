import React from 'react';
import Nav from '../nav/Nav';

import './Header.css';

export default function Header() {
  return (
    <div className="Header">
      <h1>Nick Day</h1>
    </div>
  );
}
